import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Container } from '@qga/roo-ui/components';
import Text from '@/components/Text';
import CustomerValuePropositions from '@/v2/components/ui/CustomerValuePropositions';

const StyledText = styled(Text)`
  display: block;
  font-family: 'Jetstar';
`;

const Card = ({ children, title, subtitle, link }) => (
  <Flex flex={1} flexDirection="column" alignItems={[null, null, 'center']}>
    <Box py={[null, null, '1']} px={[null, null, '2']}>
      <Flex
        flex="1"
        flexDirection={[null, null, 'column']}
        justifyContent={[null, null, 'center']}
        alignItems="center"
        textAlign={[null, null, 'center']}
        mt="1"
        maxWidth={[null, null, '294px']}
      >
        <Flex
          mb={[null, null, 2]}
          mr={[2, null, null]}
          minWidth={['40px', null, '48px']}
          alignItems="center"
          justifyContent="center"
        >
          {children}
        </Flex>
        <Flex flexDirection="column" alignItems={[null, null, 'center']}>
          <StyledText bold fontSize={['xs', null, 'base']}>
            {title}
          </StyledText>
          {subtitle && <StyledText fontSize="xs">{subtitle}</StyledText>}
        </Flex>
      </Flex>
    </Box>
    {link}
  </Flex>
);

const ValueProposition = ({ withTitle, ...props }) => (
  <Container {...props}>
    <CustomerValuePropositions withTitle={withTitle} />
  </Container>
);

Card.defaultProps = {
  subtitle: null,
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

ValueProposition.defaultProps = {
  withTitle: false,
};

ValueProposition.propTypes = {
  withTitle: PropTypes.bool,
};

export default ValueProposition;
