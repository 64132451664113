import Heading from '@/components/Heading';
import { Flex, Text } from '@qga/roo-ui/components';
import Proposition from './components/Proposition';
import BackgroundImage from '@/components/BackgroundImage';
import getAsset from '@/utils/getAsset';
import React from 'react';
import QantasPointsInfo from '@/v2/components/ui/CustomerValuePropositions/components/QantasPointsInfo';

export type ValuePropositionProps = {
  withTitle?: boolean;
};

const CustomerValuePropositions = ({ withTitle }: ValuePropositionProps) => {
  return (
    <Flex as="section" flexDirection="column" gap="6">
      {withTitle && (
        <Heading.h3 as="h2">
          Why book with <Text color="brand.primary">Jetstar Holidays?</Text>
        </Heading.h3>
      )}
      <Flex
        justifyContent="space-between"
        gap="6"
        flexDirection={['column', null, 'row']}
      >
        {/*  Fly and Save  */}
        <Proposition
          icon={<Proposition.Icon name="flyAndStay" />}
          title={
            <Proposition.Title>
              Combine your flights + hotel and save
            </Proposition.Title>
          }
          link={
            <Proposition.Link external href="/deals">
              View Holiday Deals
            </Proposition.Link>
          }
        />
        {/*  Ancillaries  */}
        <Proposition
          icon={<Proposition.Icon name="baggageAndSeat" />}
          title={
            <Proposition.Title>
              20kg checked baggage and standard seat selection included
            </Proposition.Title>
          }
          link={
            <Proposition.Link external href="/extras">
              Find out more
            </Proposition.Link>
          }
        />
        {/*  Sales  */}
        <Proposition
          icon={<Proposition.Icon name="sale" />}
          title={
            <Proposition.Title>
              Access exclusive sales and deals only available as a package
            </Proposition.Title>
          }
          link={
            <Proposition.Link
              external
              href="https://www.jetstar.com/au/en/jetmail-signup?pid=holidays:jetmail"
            >
              Sign up to Jetmail
            </Proposition.Link>
          }
        />
        {/*  Qantas Points  */}
        <Proposition
          icon={
            <BackgroundImage
              height="48px"
              width="48px"
              src={getAsset('/images/icons/ic_qff.svg')}
              alt="Book using Qantas Points"
            />
          }
          title={
            <Proposition.Title>
              Save your money, book using Qantas Points
            </Proposition.Title>
          }
          link={<QantasPointsInfo />}
        />
      </Flex>
    </Flex>
  );
};

export default CustomerValuePropositions;
