import styled from '@emotion/styled';
import { BackgroundImage as BaseBackgroundImage } from '@qga/roo-ui/components';
import { themeGet, backgroundColor } from 'styled-system';

const BackgroundImage = styled(BaseBackgroundImage)`
  position: relative;

  &:before {
    content: 'Image coming soon';
    color: ${themeGet('colors.text.hint')};
    ${backgroundColor}
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

BackgroundImage.defaultProps = {
  backgroundColor: 'background.page',
};

export default BackgroundImage;
