import { Icon as RooIcon, Flex } from '@qga/roo-ui/components';
import Heading from '@/components/Heading';
import LinkBase from '@/components/Link';
import { ComponentProps, ReactNode } from 'react';

export type PropositionProps = {
  icon?: ReactNode;
  title?: ReactNode;
  link?: ReactNode;
};

const Icon = (props: ComponentProps<typeof RooIcon>) => (
  <RooIcon size={48} color="brand.primary" {...props} />
);

const Title = (props: ComponentProps<typeof Heading.h6>) => (
  <Heading.h6 as="h2" color="text.body" {...props} />
);

const Link = (
  props: Partial<ComponentProps<typeof LinkBase.Text>> & { href: string },
  // @ts-ignore - bad inference from JS makes props required
) => <LinkBase.Text fontSize="xs" lineHeight="20px" {...props} />;

const Proposition = ({ title, icon, link }: PropositionProps) => {
  return (
    <Flex
      flex="1 1 0"
      gap={['6', null, '4']}
      flexDirection={['row', null, 'column']}
    >
      {icon}
      <Flex alignItems="flex-start" gap="1" flexDirection="column">
        {title}
        {link}
      </Flex>
    </Flex>
  );
};

Proposition.Link = Link;
Proposition.Title = Title;
Proposition.Icon = Icon;

export default Proposition;
