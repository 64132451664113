import { keys, compose, map, omit } from 'ramda';

import { convertRouteToOrigin } from '@/utils/convertRouteToOrigin';

const byCheapestPrice = (getPriceAmount) => (a, b) =>
  getPriceAmount(a) - getPriceAmount(b);

const getCheapestOriginForProcuredOffer = (procuredOffer) => {
  if (!procuredOffer) return null;

  // eslint-disable-next-line max-len
  const rooms = procuredOffer?.rooms;

  const { packageOffers } = rooms[0].leadInOffers[0];

  const packageOffersArray = compose(
    map((key) => ({ route: key, ...packageOffers[key] })),
    keys,
  )(packageOffers);

  const cheapestPackageOffer = packageOffersArray.sort(
    byCheapestPrice((offer) => offer.perTraveller.amount),
  )[0];

  return {
    origin:
      (cheapestPackageOffer &&
        convertRouteToOrigin(cheapestPackageOffer.route)) ||
      null,
    price: {
      ...omit(['route'], cheapestPackageOffer),
    },
  };
};

export default getCheapestOriginForProcuredOffer;
