import PropTypes from 'prop-types';
import ProcuredOffer from '@experiences-ui/shared/propTypes/ProcuredOffer';
import Occupants from './Occupants';
import Tag from './Tag';
import Price from './Price';
import PropertyContent from './PropertyContent';

const Sashing = {
  flightPromoCode: PropTypes.string,
  flightPillText: PropTypes.string,
};
const DealBanner = {
  headline: PropTypes.string,
  title: PropTypes.string,
  termsConditions: PropTypes.string,
};
const HomePageHeroBanner = {
  headline: PropTypes.string,
  tagline: PropTypes.string,
  CTA: PropTypes.string,
  termsConditions: PropTypes.string,
};
const CampaignLandingPage = {
  headline: PropTypes.string,
  tagline: PropTypes.string,
  introCopy: PropTypes.string,
  tileHeadline: PropTypes.string,
  tileTermsConditions: PropTypes.string,
  destinationHeadline: PropTypes.string,
  destinations: PropTypes.arrayOf(PropTypes.string),
  longFormTermsConditions: PropTypes.string,
};
const DestinationPromoText = {
  text: PropTypes.string,
  termsConditions: PropTypes.string,
};
export const CalendarMessaging = {
  title: PropTypes.string,
  noOriginMessage: PropTypes.string,
  unsupportedRouteMessage: PropTypes.string,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string,
      message: PropTypes.string,
    }),
  ),
};
const PromotedOrigin = {
  origin: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  }),
  price: PropTypes.shape(Price),
};

const Property = {
  id: PropTypes.string,
  name: PropTypes.string,
  tagline: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.shape(Tag)),
  destination: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    origins: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
};

export const PropertyDetails = {
  property: PropTypes.shape(Property),
  pillText: PropTypes.string,
  tagline: PropTypes.string,
  nights: PropTypes.number,
  travellers: PropTypes.shape(Occupants),
  cheapestOrigin: PropTypes.shape(PromotedOrigin),
};

const CampaignPropertyContent = {
  ...Property,
  ...PropertyContent,
};

const CampaignFeaturedProperty = {
  id: PropTypes.string,
  type: PropTypes.string,
  origins: PropTypes.arrayOf(PropTypes.string),
  pillText: PropTypes.string,
  tagline: PropTypes.string,
  nights: PropTypes.number,
  travellers: PropTypes.shape(Occupants),
  cheapestOrigin: PropTypes.shape(PromotedOrigin),
  propertyContent: PropTypes.shape(CampaignPropertyContent),
};

const CampaignProcuredOffer = {
  id: PropTypes.string,
  type: PropTypes.string,
  propertyContent: PropTypes.shape(CampaignPropertyContent),
  procuredOffer: PropTypes.shape(ProcuredOffer),
};

export const Tile = PropTypes.oneOfType([
  PropTypes.shape(CampaignFeaturedProperty),
  PropTypes.shape(CampaignProcuredOffer),
]);

export default {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  slug: PropTypes.string,
  priceForAdult: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  activeDestinations: PropTypes.arrayOf(PropTypes.string),
  sashing: PropTypes.shape(Sashing),
  dealBanner: PropTypes.shape(DealBanner),
  homePageHeroBanner: PropTypes.shape(HomePageHeroBanner),
  campaignLandingPage: PropTypes.shape(CampaignLandingPage),
  destinationPromoText: PropTypes.shape(DestinationPromoText),
  calendar: PropTypes.oneOfType([
    PropTypes.shape(CalendarMessaging),
    PropTypes.shape({}),
  ]),
  tiles: PropTypes.arrayOf(Tile),
};
