import { pickAll, pipe, reject, anyPass, isEmpty, isNil } from 'ramda';

const KEYS = [
  'originCode',
  'destinationCode',
  'destination',
  'departureDate',
  'departureTime',
  'returnDate',
  'returnTime',
  'adults',
  'children',
  'infants',
  'roomTypeId',
  'offerId',
  'inboundFlightNumber',
  'outboundFlightNumber',
  'countryCode',
  'travelType',
  'hidePerPersonPrice',
  'view',
];

const getSearchQuery = (searchCriteria) => {
  if (!searchCriteria) {
    return undefined;
  }

  const queryParams = pipe(
    pickAll(KEYS),
    reject(anyPass([isEmpty, isNil])),
  )(searchCriteria);

  return queryParams;
};

export default getSearchQuery;
