import React from 'react';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import PropTypes from 'prop-types';
import { Flex, Icon } from '@qga/roo-ui/components';
import { css } from '@emotion/core';
import Text from '@/components/Text';

const convertToSmall = () => css`
  border-radius: 16px;
  border-bottom-left-radius: 0;
`;

const StyledBox = styled(Flex)`
  text-transform: uppercase;
  border-radius: 22px;
  border-bottom-left-radius: 0;
  white-space: nowrap;
  ${(props) => props.small && convertToSmall()}

  @media (max-width: calc(${themeGet('breakpoints.1')} - 1px)) {
    border-radius: 16px;
    border-bottom-left-radius: 0;
  }
`;

const PromoPill = ({ message, small, background, textColor, icon }) =>
  message && (
    <StyledBox
      small={small}
      data-testid="PROMO_PILL"
      px="4"
      pt={small ? '0' : [1, null, 0]}
      mt={small ? '0' : [1, null, 0]}
      bg={background}
      height={['28px', null, small ? '28px' : '40px']}
      alignItems="center"
      color={textColor}
    >
      {icon && (
        <Icon name={icon} mr={2} mb={['3px', null, 0]} size={[14, null, 24]} />
      )}
      <Text
        bold
        fontSize={small ? ['xxs', null, 'xs'] : ['xs', null, 'sm']}
        mb={small ? '0' : ['3px', null, 0]}
        textStyle="heading"
        color={textColor}
      >
        {message}
      </Text>
    </StyledBox>
  );

PromoPill.defaultProps = {
  message: '',
  small: false,
  background: 'white',
  textColor: 'text.heading',
  icon: null,
};

PromoPill.propTypes = {
  message: PropTypes.string,
  small: PropTypes.bool,
  background: PropTypes.string,
  textColor: PropTypes.string,
  icon: PropTypes.string,
};

export default PromoPill;
