import LinkButton from '@/components/LinkButton';
import TermsAndConditionModal from '@/components/TermsAndConditionModal';
import ToggleContent from '@/libs/v2/components/ToggleContent';
import Text from '@/components/Text';
import QantasPointsMD from './qantasPoints.md';

const QantasPointsInfo = () => {
  return (
    <ToggleContent
      toggle={(open) => (
        <LinkButton textAlign="left" noPadding fontSize="sm" onClick={open}>
          <Text textAlign="left" fontSize="xs" lineHeight="20px" link bold>
            Important Information
          </Text>
        </LinkButton>
      )}
      content={(close) => (
        <TermsAndConditionModal
          //@ts-ignore - bad inference from JS
          close={close}
          title="Book using Qantas Points - Important Information"
          content={QantasPointsMD}
        />
      )}
    />
  );
};

export default QantasPointsInfo;
