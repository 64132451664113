import { splitAt } from 'ramda';
import { ORIGINS } from '@/constants';

const convertRouteToOriginCode = (route) => splitAt(3, route)[0];

const convertRouteToOrigin = (route) => {
  const originCode = convertRouteToOriginCode(route);
  return ORIGINS.find((origin) => origin.code === originCode);
};

export { convertRouteToOriginCode, convertRouteToOrigin };
