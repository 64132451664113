import PropTypes from 'prop-types';
import BlackoutDates from './BlackoutDates';

const Property = {
  name: PropTypes.string,
  id: PropTypes.string,
  tagline: PropTypes.string,
  shortDescription: PropTypes.string,
  curatedRoomTypes: PropTypes.arrayOf(PropTypes.string),
};

export default {
  name: PropTypes.string,
  title: PropTypes.string,
  destinationCode: PropTypes.string,
  originCodes: PropTypes.arrayOf(PropTypes.string),
  properties: PropTypes.arrayOf(PropTypes.shape(Property)),
  blackoutDates: PropTypes.objectOf(PropTypes.shape(BlackoutDates)),
};
